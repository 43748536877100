import * as Sentry from "@sentry/react"; // Импорт Sentry для ErrorBoundary
import axios from "axios";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import loader from "./img/loader.gif";
import { YMInitializer } from "react-yandex-metrika";
// import Audio from "./pages/media/audio";
import ym from 'react-yandex-metrika';

import "./App.scss";

const Home = lazy(() => import("./pages"));
const TermsUseAndPrivacy = lazy(() =>
  import("./pages/profile/TermsUseAndPrivacy")
);
const Cookie = lazy(() => import("./pages/profile/cookie"));
const ProfileEdit = lazy(() => import("./pages/profile-edit"));
const Profile = lazy(() => import("./pages/profile"));
const ProstateAnalysis = lazy(() => import("./pages/prostate-analysis"));
const ForDoctor = lazy(() => import("./pages/balversa/for-doctor"));
const BalversaEfficiency = lazy(() => import("./pages/balversa/efficiency"));
const BalversaSafety = lazy(() => import(".//pages/balversa/safety"));
const BalversaInstruction = lazy(() => import("./pages/balversa/instruction"));
const BalversaMechanism = lazy(() => import("./pages/balversa/mechanism"));
const BalversaDiagnostics = lazy(() => import("./pages/balversa/diagnostics"));
const Balversa = lazy(() => import("./pages/balversa"));
const NoAccess = lazy(() => import("./pages/noAccess"));
const Calc = lazy(() => import("./pages/calc"));
const ResultRpj = lazy(() => import("./pages/board/resultrpj"));
const LocalRpj = lazy(() => import("./pages/board/localrpj"));
const MetaRpj = lazy(() => import("./pages/board/metarpj"));
const Board = lazy(() => import("./pages/board"));
const Podcast = lazy(() => import("./pages/media/audio"));
const PodcastList = lazy(() => import("./pages/media/audios"));
const Video = lazy(() => import("./pages/media/video"));
const VideoList = lazy(() => import("./pages/media/videos"));
const NmkrrpjResult = lazy(() => import("./pages/erleada/nmkrrpj/result"));
const NmkrrpjSurvival = lazy(() => import("./pages/erleada/nmkrrpj/survival"));
const NmkrrpjAbout = lazy(() => import("./pages/erleada/nmkrrpj/about"));
const ErleadaNmkrrpj = lazy(() => import("./pages/erleada/nmkrrpj"));
const MgchrpjResult = lazy(() => import("./pages/erleada/mgchrpj/result"));
const MgchrpjSurvival = lazy(() => import("./pages/erleada/mgchrpj/survival"));
const MgchrpjAbout = lazy(() => import("./pages/erleada/mgchrpj/about"));
const ErleadaMgchrpj = lazy(() => import("./pages/erleada/mgchrpj"));
const ErleadaApplication = lazy(() => import("./pages/erleada/application"));
const ErleadaInstruction = lazy(() => import("./pages/erleada/instruction"));
const ErleadaAbout = lazy(() => import("./pages/erleada/about"));
const Medications = lazy(() => import("./pages/medications"));
const Blog = lazy(() => import("./pages/blogg"));
const Post = lazy(() => import("./pages/post"));
const Topical = lazy(() => import("./pages/topical"));
const Media = lazy(() => import("./pages/media"));
const ClinicEnd = lazy(() => import("./pages/clinicTest/clinicEnd"));
const ClinicTest = lazy(() => import("./pages/clinicTest/clinicTest"));
const ClinicDifficulty = lazy(() =>
  import("./pages/clinicTest/clinicDifficulty")
);
const ClinicList = lazy(() => import("./pages/clinicTest/clinicList"));
const Erleada = lazy(() => import("./pages/erleada"));
const Blogs = lazy(() => import("./pages/blogs"));

const tg = window.Telegram.WebApp;

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const url = window.location.protocol + "//" + window.location.hostname;
  const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
  //const url = "http://localhost:3000";
  // const userId = 801384711;
  //const userId = 692854890;

  // useYandexMetrika(); // Подключаем метрику

  // Передаем user_id для группировки данных в метрику
  useEffect(() => {
    if (ym && userId) {
      ym(96110260, 'userParams', {
        env: 'test',
        UserID: String(userId)
    });
    }
  }, [userId]);

  useEffect(() => {
    if (ym && userId) {
      ym(96110260, 'setUserID', String(userId));
    }
  }, [userId]);

  useEffect(() => {
    tg.expand();
    axios
      .get(`${url}/api/webapp/check_user_id?user_id=${userId}`)
      .then((res) => {
        setIsLoggedIn(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err); // Отправка ошибки в Sentry
      })
      .finally(setIsLoading(false));
  });

  const location = useLocation();

  useEffect(() => {
    ym("hit", location.pathname, {
      params: {
        title: document.title,
        UserID: userId,
      },
    });
  }, [location.pathname, userId]);

  // Бэк при обращении к этому эндпоинту также обновляет дату последней активности пользователя
  useEffect(() => {
    axios
      .post(`${url}/api/user_activity/`, {
        user: userId,
        first_app_use: true,
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err); // Отправка ошибки в Sentry
      });
  }, []);

  // Создаем запасной компонент для отображения ошибок
  const FallbackComponent = ({ error, componentStack, resetError }) => (
    <div>
      <h2>Произошла ошибка</h2>
      <p>Мы уже работаем над ее устранением.</p>
      <button onClick={resetError}>Попробовать снова</button>
    </div>
  );
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      <Suspense fallback={<>Загружаем страницу</>}>
        <div className="App">
          <YMInitializer accounts={[96110260]} options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                }}/>
          {isLoading ? (
            <img src={loader} alt="loader" className="loader" />
          ) : (
            <Routes>
              <Route
                path="/"
                element={isLoggedIn ? <Home url={url} /> : <NoAccess />}
                exact
              />
              <Route
                path="/profile"
                element={<Profile url={url} userId={userId} />}
              />
              <Route
                path="/profile/edit"
                element={<ProfileEdit url={url} userId={userId} />}
              />
              <Route path="/profile/cookie" element={<Cookie url={url} />} />
              <Route
                path="/profile/tof"
                element={<TermsUseAndPrivacy url={url} />}
              />
              <Route path="/board" element={<Board url={url} />} />
              <Route path="/board/metarpj" element={<MetaRpj url={url} />} />
              <Route path="/board/localrpj" element={<LocalRpj url={url} />} />
              {["/board/metarpj/:id", "/board/localrpj/:id"].map(
                (path, index) => {
                  return (
                    <Route
                      path={path}
                      element={<ResultRpj url={url} />}
                      key={index}
                    />
                  );
                }
              )}
              <Route
                path="/blogs"
                element={<Blogs navHead={true} url={url} />}
              />
              <Route
                path="/prostate-analysis"
                element={<ProstateAnalysis url={url} />}
              />
              <Route path="/blog/:blogId" element={<Blog url={url} />} />
              <Route path="/calc" element={<Calc url={url} />} />
              <Route path="/meds" element={<Medications url={url} />} />
              <Route path="/erleada">
                <Route index element={<Erleada url={url} />} />
                <Route path="about" element={<ErleadaAbout url={url} />} />
                <Route
                  path="instruction"
                  element={<ErleadaInstruction url={url} />}
                />
                <Route
                  path="application"
                  element={<ErleadaApplication url={url} />}
                />
                <Route path="mgchrpj">
                  <Route index element={<ErleadaMgchrpj url={url} />} />
                  <Route path="about" element={<MgchrpjAbout url={url} />} />
                  <Route
                    path="survival"
                    element={<MgchrpjSurvival url={url} />}
                  />
                  <Route path="result" element={<MgchrpjResult url={url} />} />
                </Route>
                <Route path="nmkrrpj">
                  <Route index element={<ErleadaNmkrrpj url={url} />} />
                  <Route path="about" element={<NmkrrpjAbout url={url} />} />
                  <Route
                    path="survival"
                    element={<NmkrrpjSurvival url={url} />}
                  />
                  <Route path="result" element={<NmkrrpjResult url={url} />} />
                </Route>
              </Route>
              <Route path="/balversa">
                <Route index element={<Balversa />} />
                <Route
                  path="diagnostics"
                  element={<BalversaDiagnostics url={url} />}
                />
                <Route
                  path="instruction"
                  element={<BalversaInstruction url={url} />}
                />
                <Route
                  path="mechanism"
                  element={<BalversaMechanism url={url} />}
                />
                <Route path="safety" element={<BalversaSafety url={url} />} />
                <Route
                  path="efficiency"
                  element={<BalversaEfficiency url={url} />}
                />
                <Route path="for-doctor" element={<ForDoctor url={url} />} />
              </Route>
              <Route path="/clinic" element={<ClinicList url={url} />} />
              <Route
                path="/clinic/:testId"
                element={<ClinicDifficulty url={url} />}
              />
              <Route
                path="/clinic/:testId/:difficulty"
                element={<ClinicTest url={url} />}
              />
              <Route
                path="/clinic/test-end/:testId"
                element={<ClinicEnd url={url} />}
              />
              <Route path="/media">
                <Route index element={<Media url={url} />} />
                <Route
                  path="videoList"
                  element={<VideoList navHead={true} url={url} />}
                />
                <Route
                  path="videoList/:videoId"
                  element={<Video url={url} />}
                />
                {/* <Route path="audio" element={<Audio />} /> */}
                <Route
                  path="podcastList"
                  element={<PodcastList navHead={true} url={url} />}
                />
                <Route
                  path="podcastList/:podcastId"
                  element={<Podcast url={url} />}
                />
              </Route>
              <Route
                path="/topical"
                element={<Topical navHead={true} url={url} />}
              />
              <Route path="/post/:postId" element={<Post url={url} />} />
              <Route path="*" element={<p>Path not resolved</p>} />
            </Routes>
          )}
        </div>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default App;
